'use client';

import { ErrorBoundary } from 'components';
import { incrementTelemetry } from 'core-events';
import React from 'react';

import { RightSideMenu } from '@videoblocks/shared-components';

import { UserData } from '../../types';
import { imagesUrl } from '../lib/constants';
import { getRightSideMenuProps } from './utils';

const BetaBadgeClasses = 'text-xs text-white bg-blue-1000 rounded-lg px-2 py-1';

const RightSideMenuContainer = (props: UserData) => {
    const rightSideMenuProps = getRightSideMenuProps(props);

    return (
        <ErrorBoundary fallback={<p>Something went wrong</p>}>
            <RightSideMenu
                {...{
                    ...rightSideMenuProps,
                    logger: incrementTelemetry,
                    BetaBadgeClasses,
                    imagesUrl,
                }}
            />
        </ErrorBoundary>
    );
};

export default RightSideMenuContainer;
